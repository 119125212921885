<template>
  <form @submit.prevent="send" class="settings">
    <div class="settings__body">
        <div class="app-form">
          <div class="app-form__header">Получать уведомления о новых подписках?</div>
          <div class="app-form__content">
            <div class="settings__descr">Вы будите получать уведомления на почту, каждый раз, когда будет оформлена новая подписка на наличие товара</div>
            <label>
              <input type="checkbox" v-model="local_is_receive_new_subscription_notifications"> <span>Да, хочу получать</span>
            </label>
          </div>
        </div>
        <div class="app-form">
          <div class="app-form__header">Получать уведомления от приложения?</div>
          <div class="app-form__content">
            <div class="settings__descr">Мы будем уведомблять вас об нововведениях (доработках) приложения</div>
            <label>
              <input type="checkbox" v-model="local_is_receive_app_update_notifications"> <span>Да, хочу получать</span>
            </label>
          </div>
        </div>
        <div class="app-form">
          <div class="app-form__header">Собирать номера телефонов?</div>
          <div class="app-form__content">
            <div class="settings__descr">Вы можете собирать номера телефов чтобы отправлять уведомления о наличии в смс-сообщениях</div>
            <label>
              <input type="checkbox" v-model="local_is_active_sms"> <span>Да, хочу собирать</span>
            </label>
          </div>
        </div>



    </div>


    <app-button type="submit" className="settings__btn" value="Сохранить" width="300px" />


    <app-message className="settings__message" ref="app-message" />
  </form>
</template>

<script>
import AppButton from "../components/base-ui/app-button";

export default {
  name: 'settings',
  components: {AppButton},
  data() {
    return {
      local_is_receive_new_subscription_notifications: false,
      local_is_receive_app_update_notifications: false,
      local_is_active_sms: false,
    }
  },
  computed: {
    is_receive_new_subscription_notifications() {
      return this.$store.state.account.accountData.is_receive_new_subscription_notifications || false;
    },
    is_receive_app_update_notifications() {
      return this.$store.state.account.accountData.is_receive_app_update_notifications || false;
    },
    is_active_sms() {
      return this.$store.state.account.accountData.is_active_sms || false;
    },
  },
  methods: {
    send() {
      return this.$store.dispatch('updateAccount', {
        is_receive_new_subscription_notifications: this.local_is_receive_new_subscription_notifications,
        is_receive_app_update_notifications: this.local_is_receive_app_update_notifications,
        is_active_sms: this.local_is_active_sms
      })
        .then(() => {
          this.$refs['app-message'].open({
            type: 'success',
            message: 'Сохранено',
          })
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'Ошибка',
          })
        })
    },
  },
  watch: {
    is_receive_new_subscription_notifications() {
      this.local_is_receive_new_subscription_notifications = this.is_receive_new_subscription_notifications;
    },
    is_receive_app_update_notifications() {
      this.local_is_receive_app_update_notifications = this.is_receive_app_update_notifications;
    },
    is_active_sms() {
      this.local_is_active_sms = this.is_active_sms;
    }
  },
  mounted() {
    this.local_is_receive_new_subscription_notifications = this.is_receive_new_subscription_notifications;
    this.local_is_receive_app_update_notifications = this.is_receive_app_update_notifications;
    this.local_is_active_sms = this.is_active_sms;
  }
}
</script>

<style lang="scss">
.settings {
  padding: 30px;
  position: relative;

  &__body {
    display: flex;
    justify-content: flex-start;

    & > div {
      margin-right: 30px;
    }
  }

  &__message {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  &__btn {
    margin-top: 30px;
  }

  &__descr {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.case {
  max-width: 500px;
  margin-bottom: 20px;

  &__title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__descr {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
</style>